<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <!-- <v-container style=" margin-top: -25px;">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none;"
              >
                <v-btn color="secondary">
                  <v-icon color="black">
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
        </v-row>
      </v-container> -->
      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <material-card icon="mdi-receipt" icon-small color="secondary">
              <div id="dvTable"></div>
              <v-col>
                <v-row
                  style="float: right; margin-right: 10px; margin-top: -50px"
                >
                  <v-chip
                    outlined
                    color="green"
                    @click="csvDownload"
                    style="cursor: pointer"
                    class="mr-5"
                  >
                    <v-icon> mdi-download-circle</v-icon>
                    <span class="black--text font-weight-bold"> CSV </span>
                  </v-chip>
                  <v-chip
                    outlined
                    color="green"
                    @click="pdfDownload"
                    style="cursor: pointer"
                  >
                    <v-icon> mdi-download-circle</v-icon>
                    <span class="black--text font-weight-bold"> PDF </span>
                  </v-chip>
                </v-row>
              </v-col>
              <v-card-title>
                <v-col>
                  <v-row>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getToday"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Today
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getYesterday"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Yesterday
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getBeforeYesterday"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        {{ beforeYesterday }}
                      </v-card-text>
                    </v-btn>

                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getMonthly"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Monthly Sales
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getYearly"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Yearly Sales
                      </v-card-text>
                    </v-btn>
                  </v-row>
                  <div
                    class="total-price"
                    style="width: 200px; margin-top: -50px"
                    dark
                  >
                    <v-card-text class="white--text font-weight-bold">
                      <v-row justify="space-between">
                        <div class="my-1">Cash:</div>
                        <div class="my-1">{{ currencyFormat(cashTotal) }}</div>
                      </v-row>
                      <v-row justify="space-between">
                        <div class="my-1">Card:</div>
                        <div class="my-1">{{ currencyFormat(cardTotal) }}</div>
                      </v-row>
                      <v-row justify="space-between">
                        <div class="my-1">Credit:</div>
                        <div class="my-1">
                          {{ currencyFormat(creditTotal) }}
                        </div>
                      </v-row>
                      <v-row justify="space-between">
                        <div class="my-1">Voucher:</div>
                        <div class="my-1">
                          {{ currencyFormat(voucherTotal) }}
                        </div>
                      </v-row>
                      <v-row justify="space-between">
                        <div class="my-1">Cheque:</div>
                        <div class="my-1">
                          {{ currencyFormat(chequeTotal) }}
                        </div>
                      </v-row>
                      <v-row justify="space-between">
                        <div class="my-1">Total Income:</div>
                        <div class="my-1">
                          {{ currencyFormat(incomeTotal) }}
                        </div>
                      </v-row>
                      <v-row justify="space-between" v-if="totalReturns">
                        <div class="my-1">Total Returns:</div>
                        <div class="my-1">
                          {{ currencyFormat(totalReturns) }}
                        </div>
                      </v-row>
                      <v-row justify="space-between" v-if="totalPettyCash">
                        <div class="my-1">Petty Cash:</div>
                        <div class="my-1">
                          {{ currencyFormat(totalPettyCash) }}
                        </div>
                      </v-row>
                    </v-card-text>
                  </div>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="initialFinal"
                        label="From"
                        @click="(setInitial = true), dateModalActive()"
                        outlined
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="endFinal"
                        label="To"
                        @click="(setEnd = true), dateModalActive()"
                        outlined
                      />
                    </v-col>
                    <v-btn
                      color="secondary"
                      style="margin-top: 20px; margin-bottom: 10px"
                      dark
                      @click="getData"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Get Data
                      </v-card-text>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-card-title>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  style="margin-top: -35px"
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :search="search"
                :headers="headers"
                :items="sales"
                item-key="billNo"
                class="elevation-1"
              >
                <template v-slot:[`item.orderType`]="{ item }">
                  <div>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 0"
                    >
                      <v-icon left> mdi-food-takeout-box </v-icon>
                      Pick Up
                    </v-chip>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 1"
                    >
                      <v-icon left> mdi-bike-fast </v-icon>
                      Delivery
                    </v-chip>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 2"
                    >
                      <v-icon left> mdi-food-fork-drink </v-icon>
                      Dine In
                    </v-chip>
                  </div>
                </template>

                <template v-slot:[`item.billDate`]="{ item }">
                  {{ item.billDate | localDate }}
                </template>
              </v-data-table>
            </material-card>
          </v-col></v-row
        >
      </v-container>
    </v-card-text>

    <v-dialog v-model="dateModal" persistent width="290px">
      <div>
        <v-date-picker v-model="datePick" color="secondary">
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="closeDateModal"> Cancel </v-btn>
          <v-btn text color="secondary" @click="addDate"> OK </v-btn>
        </v-date-picker>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ValidationForms from "./n/ValidationForms.vue";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

var moment = require("moment");

export default {
  components: { Loading, ValidationForms },
  data() {
    return {
      search: "",
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,

      expanded: [],
      singleExpand: false,

      sales: [],
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      dataFiltered: [],
      totalPettyCash: 0,
      totalReturns: 0,

      headers: [
        // { text: "Invoice Id", value: "billHeaderId", sortable: false },
        { text: "Invoice", value: "billNo" },

        { text: "Order Type", value: "orderType" },
        { text: "Invoice Date", value: "billDate" },

        { text: "Customer", value: "custName" },
        { text: "Bill Total", value: "billTotal" },
        { text: "GST", value: "vatTotal" },
        { text: "Discount", value: "discountTotal" },
        // { text: "Service Charges", value: "serviceCharges" },
        // { text: "Order Status", value: "orderStatus" }
      ],
      paymentView: false,
      billPayment: {
        billPaymentNo: 0,
        billNo: 0,
        payMethod: "card",
        payAmount: 0,
      },

      payMethod: [
        { text: "Card ", value: "card" },
        { text: "Cash ", value: "cash" },
        { text: "Credit ", value: "credit" },
        { text: "Cheque ", value: "cheque" },
        { text: "Voucher ", value: "voucher" },
      ],

      setInitial: false,
      dateModal: false,
      datePick: "",
      editedDate: {
        from: "",
        to: "",
      },
      initialFinal: "",
      endFinal: "",

      setInitial: "false",
      setEnd: "false",
      today: "",
      yesterday: "",
      beforeYesterday: "",
      specificDate: "",
      totalPrice: 0,

      itemsFormatted: [],
      fileTitle: "",
      csvHeaders: {
        billDate: "billDate",
        billHeaderId: "billHeaderId",
        billNo: "billNo",
        billTotal: "billTotal",
        billType: "billType",
        custName: "custName",
        discountTotal: "discountTotal",
        orderStatus: "orderStatus",
        orderType: "orderType",
        serviceCharges: "serviceCharges",
        vatTotal: "vatTotal",
      },
      pdfHeaders: [
        "billHeaderId",
        "billNo",
        "billDate",

        "billType",
        "billTotal",

        "discountTotal",
        "orderStatus",
        "custName",

        "serviceCharges",
        "orderType",
        "vatTotal",
      ],
      singledata: [],
      pdfData: [],
      pdfDataTest: [],
    };
  },
  created() {
    this.today = new Date();
    const timeStamp = new Date().getTime();
    const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
    const beforeYesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000 * 2;

    const yesterdayDate = new Date(yesterdayTimeStamp);
    this.yesterday = yesterdayDate;
    const beforeYesterdayDate = new Date(beforeYesterdayTimeStamp);
    this.beforeYesterday = beforeYesterdayDate;

    this.initialFinal = moment(this.today).format("MMMM Do YYYY");
    this.endFinal = moment(this.today).format("MMMM Do YYYY");

    this.today = moment(this.today).format("YYYY-M-D");
    this.yesterday = moment(this.yesterday).format("YYYY-M-D");
    this.beforeYesterday = moment(this.beforeYesterday).format("YYYY-M-D");
    this.getDataFromApi();
  },
  computed: {
    cashTotal() {
      return (
        this.sales
          ?.map((x) => x.cash)
          .filter((x) => x > 0)
          .reduce((a, b) => {
            return a + b;
          }, 0) || 0
      );
    },
    cardTotal() {
      return (
        this.sales
          ?.map((x) => x.card)
          .filter((x) => x > 0)
          .reduce((a, b) => {
            return a + b;
          }, 0) || 0
      );
    },
    creditTotal() {
      return (
        this.sales
          ?.map((x) => x.credit)
          .filter((x) => x > 0)
          .reduce((a, b) => {
            return a + b;
          }, 0) || 0
      );
    },
    voucherTotal() {
      return (
        this.sales
          ?.map((x) => x.voucher)
          .filter((x) => x > 0)
          .reduce((a, b) => {
            return a + b;
          }, 0) || 0
      );
    },
    chequeTotal() {
      return (
        this.sales
          ?.map((x) => x.cheque)
          .filter((x) => x > 0)
          .reduce((a, b) => {
            return a + b;
          }, 0) || 0
      );
    },
    incomeTotal() {
      return (
        this.cashTotal +
        this.cardTotal +
        this.creditTotal +
        this.voucherTotal +
        this.chequeTotal
      );
    },
  },
  watch: {},
  filters: {
    // moment: function(date) {
    //   return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    // },
    localDate: function (date) {
      return moment.utc(date).local().format("MMM Do YYYY - h:mm a");
    },
  },

  methods: {
    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    pdfDownload() {
      const document = {
        pageOrientation: "landscape",
        content: [
          {
            text:
              "Sales Report   :  " + this.initialFinal + " to " + this.endFinal,
            fontStyle: 15,
            lineHeight: 2,
          },
          {
            text: "Total Income: $" + this.totalPrice,
            fontStyle: 15,
            lineHeight: 2,
          },

          {
            columns: [
              { text: "Bill Date", width: 150 },
              { text: "Bill No", width: 50 },
              { text: "Total", width: 50 },
              { text: "Bill Type", width: 100 },
              { text: "Discount", width: 100 },
              // { text: "Order Status", width: 100 },
              { text: "Order Type", width: 100 },
              { text: "Service", width: 50 },
              { text: "Vat", width: 50 },
            ],
            lineHeight: 2,
          },
        ],
      };
      this.sales.forEach((sales) => {
        document.content.push({
          columns: [
            {
              text: moment(sales.billDate).format("MMMM Do YYYY, h:mm a"),
              width: 150,
            },

            { text: sales.billNo, width: 50 },
            { text: sales.billTotal, width: 50 },
            { text: sales.billType, width: 100 },
            { text: sales.discountTotal, width: 100 },
            { text: sales.orderStatus, width: 100 },
            { text: sales.orderType, width: 100 },
            { text: sales.serviceCharges, width: 50 },
            { text: sales.vatTotal, width: 50 },
          ],

          lineHeight: 2,
        });
      });
      pdfMake
        .createPdf(document)
        .download("Sales Report_" + this.initialFinal + " to " + this.endFinal);
    },
    pdfDownloadTest() {
      console.log("Sales", this.sales);

      //The JSON string.
      var json =
        '[["Customer Id","Name","Country"],[1,"John Hammond","United States"],[2,"Mudassar Khan","India"],[3,"Suzanne Mathews","France"],[4,"Robert Schidner","Russia"]]';
      //Convert JSON string to JSON object.
      var customers = eval(json);
      //Convert JSON to HTML Table.
      var table = document.createElement("TABLE");
      table.border = "1";
      table.Id = "tblCustomers";
      //Get the count of columns.
      var columnCount = customers[0].length;
      //Add the header row.
      var row = table.insertRow(-1);
      for (var i = 0; i < columnCount; i++) {
        var headerCell = document.createElement("TH");
        headerCell.innerHTML = customers[0][i];
        row.appendChild(headerCell);
      }
      //Add the data rows.
      for (var i = 1; i < customers.length; i++) {
        row = table.insertRow(-1);
        for (var j = 0; j < columnCount; j++) {
          var cell = row.insertCell(-1);
          cell.innerHTML = customers[i][j];
        }
      }
      //Append the Table to the HTML DIV.
      var dvTable = document.getElementById("dvTable");
      dvTable.innerHTML = "";
      dvTable.appendChild(table);
      //Convert Table to PDF.

      console.log("yesss");
      console.log(json);

      var finalJson = [[]];
      for (let i = 0; i < this.sales.length; i++) {
        var single = this.sales[i];
        //console.log(single);
        let singledata = [];
        for (let value of Object.values(single)) {
          singledata.push(value);
          //console.log("val", value);
        }
        //  console.log(singledata);

        finalJson[i] = singledata;
        //this.singledata = singledata;
      }
      console.log("Finaljson", finalJson);
      var jsonObject = JSON.stringify(finalJson);
      console.log(jsonObject);
      // console.log(finalJson[0]);
      //   this.singledata = finalJson[0];
      // this.pdfData = finalJson;

      // var test = [];
      // for (var i = 0; i < finalJson.length; i++) {
      //   test.push(finalJson[i]) ;
      // }
      this.pdfData = JSON.stringify(finalJson);
      // console.log(test);
      console.log(this.pdfData);
      this.pdfDataTest =
        '[["Customer Id","Name","Country"],[1,"John Hammond","United States"],[2,"Mudassar Khan","India"],[3,"Suzanne Mathews","France"],[4,"Robert Schidner","Russia"]]';
      // let arrr = [];
      // var arr = { one: 1, two: 2, three: 3 };

      // for (let value of Object.values(arr)) {
      //   console.log("val", value);
      //   arrr.push(value);
      // }
      // console.log(finalJson);

      this.sales.forEach((item) => {
        this.itemsFormatted.push({
          billDate: item.billDate,
          billHeaderId: item.billHeaderId,
          billNo: item.billNo,
          billTotal: item.billTotal,
          billType: item.billType,
          custName: item.custName,
          discountTotal: item.discountTotal,
          orderStatus: item.orderStatus,
          orderType: item.orderType,
          serviceCharges: item.serviceCharges,
          vatTotal: item.vatTotal,
        });
      });

      var jsonObject = JSON.stringify(this.itemsFormatted);
      //  console.log(jsonObject);

      var content = {
        content: [
          { text: "Sales Report", style: "header" },
          this.initialFinal + "_to_" + this.endFinal,

          {
            style: "tableExample",
            table: {
              widths: [30, 30, 70, 30, 30, 30, 30, 70, 30, 30, 30],
              body: [this.pdfHeaders, this.pdfDataTest],
            },
          },
        ],
      };
      pdfMake.createPdf(content).download("JSON.pdf");

      html2canvas(document.getElementById("dvTable"), {
        onrendered: function (canvas) {
          var data = canvas.toDataURL();
          var docDefinition = {
            content: [
              {
                image: data,
                width: 500,
              },
            ],
          };
          var content = {
            content: [
              "First paragraph",
              "Another paragraph, this time a little bit longer to make sure, this line will be divided into at least two lines",
            ],
          };
          // pdfMake.createPdf(content).download("JSON.pdf");

          var content = "Hii";
          pdfMake.createPdf(docDefinition).download("JSONx.pdf");
          //Remove the Table.
          dvTable.innerHTML = "";
        },
      });
    },
    csvDownload() {
      this.fileTitle = "sales_" + this.initialFinal + "_to_" + this.endFinal;

      console.log(this.sales);
      this.sales.forEach((item) => {
        this.itemsFormatted.push({
          billDate: item.billDate,
          billHeaderId: item.billHeaderId,
          billNo: item.billNo,
          billTotal: item.billTotal,
          billType: item.billType,
          custName: item.custName,
          discountTotal: item.discountTotal,
          orderStatus: item.orderStatus,
          orderType: item.orderType,
          serviceCharges: item.serviceCharges,
          vatTotal: item.vatTotal,
        });
      });
      console.log(this.itemsFormatted);

      this.exportCSVFile(this.csvHeaders, this.itemsFormatted, this.fileTitle);
    },
    exportCSVFile(headers, items, fileTitle) {
      console.log("exportCSVFile");
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.clearData();
    },
    convertToCSV(objArray) {
      console.log("convertToCSV");
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },

    clearData() {
      this.itemsFormatted = [];
      console.log("Cleared");
    },
    getFirstDayOfYear(year) {
      return new Date(year, 0, 1);
    },
    getYearly() {
      const date = new Date();
      const firstDayYear = this.getFirstDayOfYear(date.getFullYear());
      this.monthFirst = moment(firstDayYear).format("YYYY-M-D");
      console.log(this.monthFirst);

      this.editedDate.from = this.monthFirst.concat("T").concat("00:00:00");
      this.editedDate.to = this.today.concat("T").concat("23:59:59");

      this.initialFinal = moment(this.monthFirst).format("MMMM Do YYYY");
      this.endFinal = moment(this.today).format("MMMM Do YYYY");

      console.log(this.editedDate);

      this.getData();
    },

    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    getMonthly() {
      const date = new Date();
      const firstDayCurrentMonth = this.getFirstDayOfMonth(
        date.getFullYear(),
        date.getMonth()
      );
      this.monthFirst = moment(firstDayCurrentMonth).format("YYYY-M-D");
      console.log(this.monthFirst);

      this.editedDate.from = this.monthFirst.concat("T").concat("00:00:00");
      this.editedDate.to = this.today.concat("T").concat("23:59:59");

      this.initialFinal = moment(this.monthFirst).format("MMMM Do YYYY");
      this.endFinal = moment(this.today).format("MMMM Do YYYY");

      console.log(this.editedDate);

      this.getData();
    },
    getToday() {
      this.specificDate = this.today;
      this.getSpecificDate();
    },

    getYesterday() {
      this.specificDate = this.yesterday;
      this.getSpecificDate();
    },
    getBeforeYesterday() {
      this.specificDate = this.beforeYesterday;
      this.getSpecificDate();
    },
    getSpecificDate() {
      this.editedDate.from = this.specificDate.concat("T").concat("00:00:00");
      this.editedDate.to = this.specificDate.concat("T").concat("23:59:59");

      this.initialFinal = moment(this.specificDate).format("MMMM Do YYYY");
      this.endFinal = moment(this.specificDate).format("MMMM Do YYYY");

      const local = new Date();
      const localUTC = moment.utc(local).local().format();

      console.log(local, localUTC);

      var isoDateString = new Date().toISOString();

      console.log(isoDateString);

      // this.editedDate.from = moment
      //   .utc(moment(this.editedDate.from).local())
      //   .format();

      // this.editedDate.to = moment
      //   .utc(moment(this.editedDate.to).local())
      //   .format();
      console.log(this.editedDate);

      this.getData();
    },
    getData() {
      this.isLoading = true;
      this.totalPettyCash = 0;
      this.totalReturns = 0;
      // this.editedDate.from = this.initialFinal;
      //  this.editedDate.to = this.endFinal;
      //console.log(this.editedDate);

      this.$http
        .post("/Sales", this.editedDate)
        .then((response) => {
          this.sales = response.data.data;
          //this.sales = this.sales.reverse();

          this.totalPrice = 0;
          for (var i = 0; i < this.sales.length; i++) {
            this.totalPrice = this.totalPrice + this.sales[i].billTotal;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Error");
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },
    getDataFromApi() {
      var local = new Date();
      var today = moment(local).format("YYYY-MM-DD");

      console.log(today);

      var utc = moment.utc(moment(local).utc()).format();
      //console.log(utc);

      this.editedDate.from = today;
      this.editedDate.to = utc;
      //console.log(this.editedDate);
      // var utcDate = moment.utc(moment(date).utc()).format();

      this.isLoading = true;

      let from = this.editedDate.from;
      let to = this.editedDate.to;

      if (new Date(from).getDate() === new Date(to).getDate()) {
        this.$http
          .get("/ProductReturns/TotalAmount", {
            params: { date: from },
          })
          .then((res) => {
            this.totalReturns = res.data.data;
          });
        this.$http
          .get("/PettyCash/TotalAmount", {
            params: { date: from },
          })
          .then((res) => {
            this.totalPettyCash = res.data.data;
          });
      }

      this.$http
        .post("/Sales", this.editedDate)
        .then((response) => {
          this.sales = response.data.data;
          // this.sales = this.sales.reverse();

          this.totalPrice = 0;
          for (var i = 0; i < this.sales.length; i++) {
            this.totalPrice = this.totalPrice + this.sales[i].billTotal;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Error");
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },

    dateModalActive() {
      var local = new Date();
      this.date = moment(local).format("YYYY-MM-DD");

      if (this.setInitial == true) {
        this.time = "00:00:00";
      }
      if (this.setEnd == true) {
        this.time = "23:59:59";
        //this.time = moment(local).format("HH:mm");
      }

      this.dateModal = true;
    },
    closeDateModal() {
      if (this.setInitial == true) {
        this.editedDate.from = "";
        this.setInitial = false;
        this.dateModal = false;
      }
      if (this.setEnd == true) {
        this.editedDate.to = "";
        this.setEnd = false;
        this.dateModal = false;
      }
    },
    addDate() {
      if (this.setInitial == true) {
        var date = this.datePick.concat("T").concat(this.time);
        var utcDate = moment.utc(moment(date).utc()).format();
        this.editedDate.from = utcDate;

        console.log(this.editedDate.from);
        this.initialFinal = moment(this.editedDate.from).format("MMMM Do YYYY");
        this.setInitial = false;
        this.dateModal = false;
        this.minDate = "";
      }
      if (this.setEnd == true) {
        var date = this.datePick.concat("T").concat(this.time);
        var utcDate = moment.utc(moment(date).utc()).format();
        this.editedDate.to = utcDate;
        console.log(this.editedDate.to);
        this.endFinal = moment(this.editedDate.to).format("MMMM Do YYYY");
        this.setEnd = false;
        this.dateModal = false;
        this.maxDate = this.date;
      }

      //this.finalDate = "Hi";
      // this.finalDate =
      //   this.editedItem.initialDate | moment("dddd, MMMM Do YYYY, h:mm:ss a");
    },
    addPayment(item) {
      console.log(item);

      this.paymentView = true;
      this.billPayment.billNo = item.billNo;
      this.billPayment.billPaymentNo = item.billNo;

      this.billPayment.payAmount = item.billTotal;
    },
  },
};
</script>
<style>
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.total-price {
  background-color: #30b72a;
  padding: 5px;
  text-transform: uppercase;
  border-radius: 5px;
  float: right;
}
</style>
